nav {
  background-color: #333;
  width: 500px;
  height: 55px;
  margin: 0px auto;

  padding: 20px 25px;
  margin-top: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 30px;
}

nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  padding: 5px;
}

nav ul li a:hover {
  color: #4caf50;
}

.button {
  background-color: #4caf50;
  border: none;
  color: rgb(255, 255, 255);
  padding: 6px 25px;
  border-radius: 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  white-space: nowrap;
}

.button:hover {
  color: white;
  background-color: #3e8e41;
}

.icon {
  filter: invert(100%);
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 5px;
  width: 40px;
  height: auto;
}

.in {
  filter: invert(100%);
  cursor: pointer;
  display: none;
}

.dropdown {
  display: none;
  position: absolute;
  right: 2rem;
  top: 60px;
  height: 0;
  width: 300px;
  background: #333;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  transition: height cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown.open {
  height: 240px;
  display: block;
}

.dropdown li,
.button {
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown li a {
  color: #fff;
}

.dropdown li a:hover {
  color: #4caf50;
}

@media (max-width: 992px) {
  nav ul {
    display: none;
  }

  .in {
    display: block;
  }
  nav {
    max-width: 100%; /* Ensure nav takes full width on smaller screens */
  }

  .dropdown,
  .button {
    display: block;
  }
}