.chat-container {
  max-width: 600px;
  min-height: 600px;
  margin: 50px auto;
  background-color: #000; /* Black background */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #fff; /* White text color */
  position: relative;
}

.message-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: calc(100% - 60px); /* Adjust based on the height of input area */
  overflow-y: auto;
}

.message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  max-width: 70%;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

.user-message {
  background-color: #007bff; /* Blue for user message */
  color: #fff;
  align-self: flex-end;
}

.ai-message {
  background-color: #4caf50; /* Green for AI message */
  color: #fff;
  align-self: flex-start;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc; /* Grey background for avatar */
}

.avatar.user {
  background-color: #007bff; /* Blue for user avatar */
}

.avatar.ai {
  background-color: #28a745; /* Green for AI avatar */
}

.message-text {
  flex: 1;
}

.message1 {
  padding: 10px;
  border-top: 1px solid #333;
  background-color: #222; /* Dark grey for input area */
  position: absolute;
  bottom: 0;
  width: calc(100% - 20px); /* Adjust to fit within container */
  display: flex;
  align-items: center;
  border-radius: 0 0 8px 8px;
}

.send-icon {
  width: 24px;
  height: 24px;
  background-color: #4caf50; /* Green color for send icon */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  margin-left: 10px;
}

.send-icon::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 14px solid #fff;
  position: absolute;
  top: 3px;
}

.send-icon.disabled {
  background-color: #666; /* Grey for disabled state */
  cursor: not-allowed;
}

.send-icon.disabled::before {
  border-bottom-color: #aaa; /* Lighter grey for disabled send icon */
}

.speech-toggle {
  position: absolute;
  bottom: 60px; /* Position it above the input area */
  left: 20px;
  display: flex;
  align-items: center;
}

.speech-toggle label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #fff; /* White text for label */
}

.speech-toggle input[type="checkbox"] {
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.speech-toggle input[type="checkbox"]:checked {
  background-color: #4caf50; /* Green when enabled */
}

.speech-toggle input[type="checkbox"]::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.2s ease;
}

.speech-toggle input[type="checkbox"]:checked::before {
  transform: translateX(20px);
}

.listen-button {
  background-color: #007bff; /* Blue color for button */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.listen-button.listening {
  background-color: #dc3545; /* Red color when listening */
}
